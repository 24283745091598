import * as React from "react"
import { Link } from "gatsby"

export default function Introduction_case_laundry() {
  return (
    <div className="introduction-case-laundry">
      <div className="intro">
        <div className="cardtype row">
          <img className="image-for-card-laundry" src="images/bubbles.png" alt="" />
          <span>Lavanderia</span>
        </div>
        <h4 className="tittle">Dinerama entrega ganhos significativos de Lift</h4>
        <p className="description-case-beer">Usando as soluções da Dinerama, essa marca de produtos de lavanderia atingiu
          +94% de incrementabilidade nas vendas diárias vs. período pré campanha.</p>
        <div className="action">
          <Link to={"/partners"}>Quero ser parceiro Dinerama</Link>
        </div>
      </div>
    </div>
  )
}