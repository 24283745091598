import * as React from "react"

export default function SucessMeasurementsLaudry() {
  return (
    <div className="sucess-measurements">
      <div className="intro-sucess-measurements">
        <h4 className="tittle-sucess-measurements">
          Métricas de sucesso com a Dinerama
        </h4>
        <p className="description-sucess-measurements">
          A marca cliente atingiu ROAS (return on ad spent) de 5,2x na campanha.
        </p>
      </div>
      <div className="row last-line-measurements box-padding">
        <div className="cases-sucess-intro">
          <h4 className="cases-roas">ROAS</h4>
        </div>
        <div className="signal">
          <h4 className="tittle-purchase-campaings">=</h4>
        </div>
        <div className="circle-campaings">
          <div className="circle-campaings-laundry">
            <h4 className="tittle-circle-laundry">5,2x</h4>
            <p className="description-circle-laundry">Return on ad spend</p>
          </div>
        </div>
      </div>
    </div>
  )
}