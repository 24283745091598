import * as React from "react";
import { Link } from "gatsby"

export default function SucessHistoriesInCaseLaudry() {
  return (
    <div className="sucess-histories-in-case">
      <div className="intro-sucess-histories-case">
        <h4 className="tittle-sucess">Histórias de Sucesso com a Dinerama</h4>
        <p className="description-sucess">Veja casos reais em que a Dinerama acelerou resultados para marcas parceiras</p>
      </div>
      <div className="cases-in-case">
        <div className="cardtype row">
          <img className="image-for-card-tittle-in-case" src="/images/beer.png" alt=""></img>
          <span>Cervejaria</span>
        </div>
        <h4 className="tittle-in-case">Dinerama turbina lançamento de produtos</h4>
        <p className="description-in-case"> Marca de bebidas lançou uma nova cerveja no mercado utilizando a tecnologia da Dinerama para
          realizar sampling com mensuração de vendas e teste A/B de recorrência.</p>
        <div className="container-item">
          <div className="row last-line box-padding">
            <div className="column">
              <span className="porcent">8,4x</span>
              <span>Return on ad spend</span>
            </div>
            <div className="row see-case">
              <Link to={"/cases"}>Ver case
                <img className="arrow-right" src="/images/arrow-right.png" alt=""></img>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}