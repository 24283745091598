import * as React from "react"

export default function ResultWithRightBuyers<T>() {
  return (
    <div className="result-with-right-buyers-case-laundry">
      <div className="intro-case-laundry">
        <h4 className="tittle-case-buyers-laundry">Resultado com os compradores certos</h4>
        <p className="description-case-laundry-right-buyers">A campanha de produtos para lavanderia converteu 79.5% em
          clientes novos para a marca ou switchers e conquistou +17p.p.
          de market share na categoria durante a promoção.</p>
      </div>
      <div className="new-customers">
        <div className="vertical-line-primary">
          <p className="tittle-for-vertical-line">Novos clientes</p>
          <p className="description-for-vertical-line">79,5%</p>
        </div>
        <div className="vertical-line">
          <p className="tittle-for-vertical-line">Switchers and captured</p>
          <p className="description-for-vertical-line">+17p.p</p>
        </div>
      </div>
      <div className="images-for-new-customers">
      <img className="new-customers-image" src="./images/image_result_with_right_buyers1.png" alt=""></img>
      <img className="new-customers-image-graphic" src="./images/image_result_with_right_buyers2.png" alt=""></img>
      </div>
    </div>
  )
}