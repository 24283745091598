import * as React from "react"

export default function ResultDeliveredCaseLaundry() {
  return (
    <div className="result-delivered-case-laundry">
      <div className="intro-case-laundry">
        <h4 className="tittle-case-result-laundry">Resultado Entregue</h4>
      </div>
      <div className="description-for-result-case-laundry">
      <div className="description-for-card-case-laundry">
        <div className="vertical-line-primary">
          <p className="tittle-for-vertical-line">Lift vs pré campanha</p>
          <p className="description-for-vertical-line">+94%</p>
        </div>
          <div className="case-laundry-description">
            <p className="description-case-laundry">Com a implementação das soluções da Dinerama, uma marca de produtos de lavanderia conseguiu um
              aumento impressionante de 94% nas vendas diárias em comparação com o período anterior à campanha.
              Esses resultados demonstram o impacto positivo e eficaz das soluções da Dinerama no aumento da
              performance e rentabilidade das marca</p>
          </div>
      </div>
        <img className="image-for-case-laundry" src="./images/image_result_delivered_laundry.png" alt=""></img>
      </div>
    </div>
  )
}