import * as React from "react"
import { Link } from "gatsby"

export default function IncreasedRecurrence() {
  return (
    <div className="acceleration-purchase-cycle">
      <div className="graphic">
        <div className="acceleration-purchase-cycle-description">
          <div className="intro-cycle">
            <img className="image-circle" src="/images/graphic_for_purchase1.png" />
          </div>
        </div>
      </div>
      <div className="intro">
        <h4 className="tittle-cycle-purchase">Aumento da recorrência</h4>
        <p className="description-cycle-purchase">Com a Dinerama, essa marca conseguiu acelerar o ciclo de compras em 27,8% durante a campanha.</p>
        <div className="porcent-for-graphic-result">
          <div className="vertical-line-primary">
            <p className="tittle-for-vertical-line">Aceleração do ciclo de compras</p>
            <p className="description-for-vertical-line">28%</p>
          </div>
          <div className="vertical-line">
            <p className="tittle-for-vertical-line">Diferença de dias de recompra</p>
            <p className="description-for-vertical-line">15</p>
          </div>
        </div>
        <div className="action">
          <Link to={"/partners"}>Quero ter esses resultados também</Link>
        </div>
      </div>
    </div>
  )
}