import ResultDeliveredCase from "../components/result_delivered_case"
import AccelerationPurchaseCycle from "../components/acceleration_purchase_cycle"
import SucessMeasurements from "../components/sucess_measurements"
import WantToBeOurPartnerCases from "../components/be_our_partner_cases"
import SucessHistoriesInCase from "../components/sucess_histories_in_case"
import Footer from "../components/footer"
import QRCodeModal from "../components/qrcode_modal"
import InstallApp from "../components/install-app"
import TagManager from "../tag-manager"
import Seo from "../seo"
import { useEffect, useRef, useState } from "react"
import asyncAnalytics from "../components/analytics"
import OurHeaderCases from "../components/header_cases"
import * as React from "react"
import Introduction_case_laundry from "../components/introduction_case_laundry"
import ResultDeliveredCaseLaundry from "../components/result_delivered_case_laundry"
import ResultWithRightBuyers from "../components/result_with_right_buyers"
import IncreasedRecurrence from "../components/increased_recurrence"
import SucessMeasurementsLaudry from "../components/sucess_measurements_laudry"
import SucessHistoriesInCaseLaudry from "../components/sucess_histories_in_case_laudry"

export default function Home(props) {
  const redirectHost = "https://go.dinerama.com.br/"
  const appStoreUrl = "https://apps.apple.com/br/app/dinerama/id1572283434"
  const googlePlayUrl = "https://play.google.com/store/apps/details?id=br.com.dinerama"
  const [url, setUrl] = useState(redirectHost + "?link=instalar")
  const [open, setOpen] = useState(false)
  const [qrCodeModalOpened, setQrCodeModalOpened] = useState(false)
  const modalRef = useRef(null)

  useEffect(() => {
    asyncAnalytics(props)
    const searchParams = new URLSearchParams(props?.location?.search)
    if (searchParams.has("app")) setOpen(true)
    searchParams.set("ga_cookie", document.cookie.split(';').map((c)=> c.split("=")).reduce((a,v) => Object.assign(a,{[v[0].trim()]:v[1]}), {})['_ga_H0509LLPJ3']);
    setUrl(redirectHost + "?" + searchParams.toString())
  }, [props])

  return (
    <div id="cases-root">
      <QRCodeModal
        url={url}
        modalRef={modalRef}
        appStoreUrl={appStoreUrl}
        googlePlayUrl={googlePlayUrl} />
      <InstallApp open={open} url={url} close={() => setOpen(false)} />
      <TagManager />
      <Seo title="Cashback em qualquer supermercado"
           description="Colocamos o poder de escolha nas suas mãos. Com a Dinerama, ganhar dinheiro de volta nas
                 compras do dia-a-dia nunca foi tão simples."/>
      <section className="main">
        <OurHeaderCases />
        <Introduction_case_laundry />
        <ResultDeliveredCaseLaundry />
        <ResultWithRightBuyers />
        <IncreasedRecurrence />
        <SucessMeasurementsLaudry />
        <WantToBeOurPartnerCases />
        <SucessHistoriesInCaseLaudry />
      </section>
      <Footer />
    </div>
  )
}